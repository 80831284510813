var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const LogIn = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M8.516 2.043c-1.283.129-2.481.974-3.086 2.177-.336.669-.446 1.294-.423 2.411.013.672.014.677.134.872a.998.998 0 0 0 1.716.003l.119-.194.032-.875c.029-.789.041-.9.131-1.139.21-.558.589-.936 1.171-1.17l.27-.108h4.8c4.386 0 4.825.006 5.092.068.419.098.633.216.928.512.296.295.414.509.512.928.063.269.068.785.068 6.472s-.005 6.203-.068 6.472a3 3 0 0 1-.16.48c-.126.259-.562.686-.834.816-.445.214-.401.212-5.538.212-4.538 0-4.77-.003-4.98-.075a1.98 1.98 0 0 1-1.262-1.206c-.088-.235-.101-.349-.13-1.133-.031-.846-.034-.876-.139-1.054a1.008 1.008 0 0 0-1.74.002c-.105.18-.109.209-.122.859-.008.369.005.849.029 1.066a3.98 3.98 0 0 0 3.475 3.52c.471.054 9.082.054 9.669 0 .748-.068 1.207-.184 1.679-.424.89-.45 1.623-1.307 1.896-2.215.239-.794.245-.962.245-7.32s-.006-6.526-.245-7.32c-.331-1.099-1.29-2.058-2.435-2.434-.659-.217-.643-.216-5.74-.227-2.596-.006-4.875.005-5.064.024m3.154 5.018c-.222.081-.42.248-.542.455-.092.156-.108.229-.107.484 0 .213.022.341.074.44.041.077.616.685 1.279 1.35L13.578 11H8.235c-4.411 0-5.374.01-5.525.055a1.02 1.02 0 0 0-.582.461c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.122.044 1.14.054 5.529.055l5.379.002-1.204 1.21c-.663.666-1.236 1.271-1.273 1.345-.229.454-.045 1.046.407 1.312.165.097.235.113.492.113.254 0 .328-.016.48-.107.225-.134 4.325-4.241 4.425-4.433.052-.099.074-.228.074-.44.001-.255-.015-.327-.106-.48-.06-.101-1.039-1.108-2.22-2.285-1.905-1.897-2.132-2.111-2.31-2.17a1 1 0 0 0-.673-.004", clipRule: "evenodd" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default LogIn;
